<template>
    <v-card color="grey-lighten-4" theme="dark" style="margin-block: 20px; margin-inline: 60px;">

        <v-img :src="image" style="max-height: 200px;"></v-img>
        <v-card-title class="text-h5">
            {{ title }}
        </v-card-title>

        <v-card-subtitle>{{ year }}</v-card-subtitle>

        <v-card-text class="text-h7">{{ text }}</v-card-text>

    </v-card>
</template>

<script>
export default {
    props: {
        title: { String },
        year: { Number },
        text: { String },
        image: { String },
    },
}
</script>