<template>
  <div>

    <Startseite />

  </div>
</template>

<script>
// @ is an alias to /src
import Startseite from '@/components/Start-seite.vue'

export default {
  name: 'HomeView',
  components: {
    Startseite
  }
}
</script>
