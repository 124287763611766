<template>
  <div>
    <Nav></Nav>
    <div style="margin-block: 50px">
      <router-view class="routerView" />
    </div>
    <HeiGeiFooter></HeiGeiFooter>
  </div>
</template>

<style scoped>
.routerView{
  margin-inline: 5%;
}
</style>

<script>
import Nav from "./components/navigations-leiste.vue";
import HeiGeiFooter from "./components/heigei-footer.vue";

export default {
  components: {
    Nav,
    HeiGeiFooter,
  },
};

</script>
