<template>
  <div>
    <div
      :style="{
        'background-image': `url(${require('@/assets/HomeBild.jpg')})`,
        height: '500px',
        'background-size': 'cover',
        'background-position': 'center',
      }"
    >
      <h1
        :style="{
          position: 'relative',
          top: '400px',
          'text-align': 'center',
          color: '#ffffff',
        }"
      >
        DPSG Stamm Heilig Geist
      </h1>
    </div>
    <BlackDivider
      title="Herzlich Willkommen beim Pfadfinder Stamm Heilig Geist"
    ></BlackDivider>
    <div style="margin-block: 20px; margin-inline: 60px">
      <v-img
        :src="require('@/assets/Stammesgeburtstag.jpg')"
        style="float: left; width: 200px; margin-inline-end: 15px"
      ></v-img>
      Am 24.05.1977 wurde unser Stamm Heilig Geist gegründet. Gruppenstunden
      fanden damals an der Heilig-Geist-Kirche an der Semmeringstraße in
      Buchholz statt. Von dort starteten wir in die unterschiedlichsten
      Abenteuer – Segeltouren auf dem IJsselmeer, viele verschiedene Standlager
      oder Radtouren nach Paris. Auch an Aktionen wie dem Domino-Day oder der
      72-Stunden-Aktion hat unser Stamm immer wieder teilgenommen.
      <br />
      2008 wurde die Kirche Heilig Geist geschlossen, 2010 die Kirche und das
      von den Pfadfindern genutzte Pfarrheim abgerissen. Unter dem Motto „Wir
      sind immer noch da!“ hat unser Stamm nicht aufgegeben. 2013 fanden wir
      unsere neue feste Heimat an der Lindenstraße, nicht weit von unserem
      Gründungsort entfernt.
      <br />
      In unserer Pfadfinder-Wohnung haben wir zwei Gruppenräume, die wir im
      Sommer 2020 frisch renoviert haben. Von dort aus starten wir in neue
      Abenteuer. Die Pfadis haben 2014 eine Deutschlandtour mit dem Zug gemacht,
      2018 ging es ins Bezirkslager nach Irland und 2019 nach Dänemark ins
      Diözesanlager. 2022 ging es endlich auch mal wieder ins Stammeslager. Als
      Piratenmannschaft haben wir Monschau entdeckt. Aber auch kleine Aktionen
      wie eine Übernachtung mit dem Trupp, Versprechensfeiern oder
      Stammesgeburtstage prägen unser Stammesleben.
      <br />
      Momentan haben wir eine Wölflings-Meute, einen Juffi-Trupp, eine
      Pfadfinder- und eine Rover-Runde. Jede Woche treffen sich die Gruppen zur
      Gruppenstunde, in denen vom gemütlich am Lagerfeuer sitzen und Stockbrot
      machen über Völkerball bis zu Projekten wie selbst Schwedenstühle bauen
      alles dabei ist. Wenn du neugierig bist, kommt einfach mal vorbei!
      <p style="margin-top: 10px">
        Wenn du Interesse an Produkten unseres Stammes hast, findest du diese
        unter
        <a
          href="https://heilig-geist.myspreadshop.de/"
          target="_blank"
          style="color: grey"
          >https://heilig-geist.myspreadshop.de/</a
        >
      </p>
    </div>
    <BlackDivider title="Unsere Aktionen und Lager"></BlackDivider>
    <ScoutActions
      v-for="(action, index) in actions"
      :key="index"
      :title="action.title"
      :year="action.year"
      :text="action.text"
      :image="action.image"
    ></ScoutActions>
  </div>
</template>

<script>
import BlackDivider from "./black-divider.vue";
import ScoutActions from "./scout-actions.vue";

export default {
  components: {
    BlackDivider,
    ScoutActions,
  },
  data() {
    return {
      actions: [
        {
          title: "Sommerlager in Monschau",
          year: 2022,
          text: "Mit Zug und Bus ging es im Juli 2022 für die Gruppenkinder und Leiter ins Sommerlager nach Monschau. Nach einer aufregenden Anreise, Zelte aufbauen und Platz erkunden besuchte uns Kapitän HolySpirit auf dem Zeltplatz. Der Kapitän und seine Crew waren mit ihrem Schiff im Sturm gekentert und brauchten Hilfe, um wieder in See stechen zu können. Die Piratenausbildung begann. Fernrohre basteln, das Schiff wieder zusammenbauen und die Suche nach dem verlorenen Schatz – all das haben die Kinder gemeistert und wurden so vom Kapitän zum Teil der Piratencrew ernannt. Was mit einer großen Piratenparty gefeiert wurde. Als waschechte Piraten – dreckig, mit Augenklappen und Papagei – ging es für die Wös und Juffis nach einer Woche in Monschau nach Hause. Pfadis und Rover blieben noch und erkundeten auf Wandertouren die Gegend rund um Monschau, zeigten ihr Sportliches Können und sammelten beim Wellnessvormittag noch mal all ihre Kräfte, um danach das Lager abzubauen. In der letzten Nacht konnten wir uns am Lagerfeuer und unter dem Sternenhimmel Monschaus noch einmal die schönsten Lagermomente ins Gedächtnis rufen, bevor wir in der Jurte und einigen Kröten uns in unsere Schlafsäcke kuschelten.",
          image: require('@/assets/Monschau_quer.png'),
        },
        {
          title: "Schlittschuhfahren",
          year: 2020,
          text: "Als ganzer Stamm haben wir uns aufs Eis getraut! Vom BDKJ haben wir in der letzten Session Freikarten für die Eishalle der Füchse in Duisburg bekommen. Mit Wölfling, Juffis und Rovern ging es an einem Samstagmittag aufs Eis. Rückwährts fahren oder mal auf einem Bein war auch kein Problem – zu mindestens für die Kinder. Während die Leiter wackelig ihre Runden an der Bande drehten, sausten unsere Juffis bei Guter-Laune-Musik mutig und rasant über das Eis. Dazu passten sie noch auf die Leiter auf, trauten sich diese einmal in die Mitte der Eisfläche – dabei sollte es doch eigentlich andersrum sein.",
          image: require('@/assets/Schlittschuh.jpg'),
        },
        {
          title: "Sommerlager in Dänemark",
          year: 2019,
          text: 'Im Sommer 2019 hat unser Diözesanverband Essen alle Stämme, Bezirke und Siedlungen eingeladen gemeinsam nach Kolding ins Houens Odde Scout Centre zu hiken und dort mit über 1500 Kindern, Jugendlichen und jungen Erwachsenen einige ereignisreiche Tage zu verbringen. Was verbindet uns mit den anderen Bezirken und Stämmen aus dem DV? - Eigentlich erstmal nichts, abgesehen von der Pfadfinderei natürlich. Dennoch passiert es irgendwie ständig, dass man aneinander klebenbleibt. Auch noch im Nachhinein kleben viele gemeinsame Erklebnisse in unserer Erinnerung fest. So zum Beispiel der erbitterte Kampf des Lösemittel-Larrys gegen den Klebstoff der uns Pfadfinder:innen zusammen hält. Auf gemeinsamen Entdeckungen, in Abenteuern und waghalsigen Aktionen konnten wir diesen magischen Klebstoff noch genauer kennen lernen. Das erste reinste Abenteuer war schon der Aufbau. Erstmalig für dieses Lager wurde uns klar, wozu das Rüsthaus 50cm lange Heringe anbietet, nämlich genau für die Kombination von Sturm und durchgeweichter Wiese durch ständige Regenschauer. Eine wahrliche Entdeckung war auch das dänische Müll-Sortierungssystem. Hartplastik? Ah ja, Tonne 7. Achso, das hatte Kontakt mit Lebensmitteln? Ne, dann Restmüll. Paddeltouren um "unsere" Halbinsel, abenteuerliche Wanderungen bei Tag und Nacht, Axtwerfen, Bogenschießen, spannende Geländespiele, riesige Großgruppenspiele, die größte Freiluft-Messe im Diözesanverband und ergreifende Versprechensfeiern sind Momente, die kein Lösemittel mehr aus unseren Erinnerungen löschen kann.',
          image: require('@/assets/Dänemark.png'),
        },
        {
          title: "Iron-Juffi",
          year: 2019,
          text: "Karten und Kompass lesen können – das gehört zum Pfadfinder sein dazu. Um ihre Fähigkeiten auf die Probe zu stellen machten sich unsere Juffis im April 2019 auf den Weg nach Hattingen, um am Iron Juffi der Diözese Essen teilzunehmen. Als Team mussten sie nur mit Hilfe von Karte und Kompass und ohne Hilfe der Leiter gegen andere Stämme antreten. Es ging darum, möglichst viele Stationen zu finden und dort Punkte zu sammeln, um am Ende als Sieger aus dem Tag hervorzugehen. Aufs Treppchen haben wir es zwar nicht geschafft, aber Spaß hatten wir! Vor allem bei der After-Party mit Curry Wurst und blauer Zuckerwatte!",
          image: require('@/assets/IronJuffi.png'),
        },
        {
          title: "Sommerlager in Irland",
          year: 2018,
          text: "In den Sommerferien 2018 ging es mit dem Bezirk Duisburg ins Lager in Irland. Nach 36 Stunden mit Bus und Fähre kamen wir in Larch Hill an. Natürlich begrüßte uns die grüne Insel mit Regen. Am ersten Morgen kam die Sonne raus und wurde zum ständigen Begleiter in den nächsten zwei Wochen. Unter dem Motto „Pott of Gold“ sammelten die Kinder die Farben des Regenbogens und fanden am Ende des Lagers den Schatz. Auch ein Hike durfte nicht fehlen. Wir wanderten 3 Tage mit dem Rucksack auf dem Rücken durch die grünen Landschaften und übernachteten an den unterschiedlichsten Orten. Nach zwei Wochen bauten wir unsere kleine Zeltstadt wieder ab und machten uns auf dem Heimweg – im Gepäck neue Freundschaften und viele erlebte Abenteuer.",
          image: require('@/assets/IrlandLager.png'),
        },
      ],
    };
  },
};
</script>

<style scoped></style>
